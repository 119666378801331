export enum ESharedKitProgressCounterTypes {
  QUANTITY = 'quantity',
  TIME = 'time',
}

// Вариант счётчика с кол-вом
interface ISharedKitProgressCounterQuantity {
  current: number;
  initial: number;
  type: ESharedKitProgressCounterTypes.QUANTITY;
}

// Вариант счётчика со временем
interface ISharedKitProgressCounterTime {
  end: number;
  start: number;
  type: ESharedKitProgressCounterTypes.TIME;
}

export type TSharedKitProgressCounterVariant = ISharedKitProgressCounterQuantity | ISharedKitProgressCounterTime;

export interface ISharedKitProgressCounterProps {
  variant: TSharedKitProgressCounterVariant;
}
